/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.modal-part-card {
  margin-top: -16px;
  margin-bottom: 16px;
  background-color: #bae7ff;
}
.compare-model-data {
  color: #faad14;
  font-weight: 600;
}
